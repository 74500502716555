
import Footer from "src/components/Footer";
import Header from "src/components/header";



const PageLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div>
      <Header />
      
      <main className="grow">

        {children}

      </main>

      <Footer />
    </div>
  );
};

export default PageLayout;
