export default function Features() {
  return (
    <section>
      <div className="max-w-6xl mx-auto md:px-4 sm:px-2">
        <div className="md:flex md:py-6 lg:py-4 border-b border-gray-200">
          <article className="flex  flex-col  border-transparent">
            <div className="grow flex flex-col p-5 pt-6">
              <div className="flex items-center space-x-3 mb-1">
                <svg
                  className="inline-flex fill-zinc-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                >
                  <path d="m6.035 17.335-4-14c-.2-.8.5-1.5 1.3-1.3l14 4c.9.3 1 1.5.1 1.9l-6.6 2.9-2.8 6.6c-.5.9-1.7.8-2-.1Zm-1.5-12.8 2.7 9.5 1.9-4.4c.1-.2.3-.4.5-.5l4.4-1.9-9.5-2.7Z" />
                </svg>
                <h3 className="font-inter-tight font-semibold text-zinc-900">
                  AI Mentor
                </h3>
              </div>
              <p className="grow max-w-md text-sm text-zinc-500">
                Now easily clear all your doubts while you study on the go with your AI Mentor
              </p>
            </div>
            <figure>
              <img
                className="h-[280px] object-cover object-left mx-auto sm:object-contain sm:h-auto"
                src={"/assets/img/feature-post-02.png"}
                width={342}
                height={280}
                alt="Feature Post 02"
              />
            </figure>
          </article>
          <article className="flex flex-col">
            <div className="grow flex flex-col p-5 pt-6">
              <div className="flex items-center space-x-3 mb-1">
                <svg
                  className="inline-flex fill-zinc-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                >
                  <path d="M8.974 16c-.3 0-.7-.2-.9-.5l-2.2-3.7-2.1 2.8c-.3.4-1 .5-1.4.2-.4-.3-.5-1-.2-1.4l3-4c.2-.3.5-.4.9-.4.3 0 .6.2.8.5l2 3.3 3.3-8.1c0-.4.4-.7.8-.7s.8.2.9.6l4 8c.2.5 0 1.1-.4 1.3-.5.2-1.1 0-1.3-.4l-3-6-3.2 7.9c-.2.4-.6.6-1 .6Z" />
                </svg>
                <h3 className="font-inter-tight font-semibold text-zinc-900">
                  Learn with AI
                </h3>
              </div>
              <p className="grow max-w-md text-sm text-zinc-500">
                AI that helps you make your learning experiance better with real wordl problems.
              </p>
            </div>
            <figure>
              <img
                className="h-[280px] object-cover object-left mx-auto sm:object-contain sm:h-auto"
                src={"/assets/img/feature-post-03.png"}
                width={342}
                height={280}
                alt="Feature Post 03"
              />
            </figure>
          </article>
          
          <article className="flex flex-col">
            <div className="grow flex flex-col p-5 pt-6">
              <div className="flex items-center space-x-3 mb-1">
                <svg
                  className="inline-flex fill-zinc-400"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                >
                  <path d="M16 2H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h8.667l3.733 2.8A1 1 0 0 0 18 17V4a2 2 0 0 0-2-2Zm0 13-2.4-1.8a1 1 0 0 0-.6-.2H4V4h12v11Z" />
                </svg>
                <h3 className="font-inter-tight font-semibold text-zinc-900">
                  Real-time Feedback
                </h3>
              </div>
              <p className="grow max-w-md text-sm text-zinc-500">
                Get instant feedback so that you can gett better with your skills.
              </p>
            </div>
            <figure>
              <img
                className="h-[280px] object-cover object-left mx-auto sm:object-contain sm:h-auto"
                src={"/assets/img/feature-post-05.png"}
                width={342}
                height={280}
                alt="Feature Post 05"
              />
            </figure>
          </article>
        </div>
      </div>
    </section>
  );
}
