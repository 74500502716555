"use client";

import { useState } from "react";
import Newsletter from "src/components/newsletter";

export default function Sidebar() {
  const [remoteJob, setRemoteJob] = useState<boolean>(false);

  return (
    <aside className="mb-8 md:mb-0 md:w-[240px] lg:w-[300px] md:ml-12 lg:ml-20 md:shrink-0 order-0 md:order-1">
      <div
        data-sticky=""
        data-margin-top="32"
        data-sticky-for="768"
        data-sticky-wrap=""
      >
        <div className="relative">
          <div className="pb-8 md:pb-16">
            <h2 className="text-3xl font-bold text-gray-700 font-inter mb-10"></h2>
            {/* List container */}
            <div className="flex flex-col">
              {/* Newletter CTA */}
              <div className="py-5 -order-1">
                <Newsletter />
              </div>
              <div>
                <div className="rounded-lg border border-slate-200 p-5">
                  <div className="flex items-center space-x-3 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="20"
                    >
                      <path
                        fill="#38BDF8"
                        fillRule="evenodd"
                        d="M.73 6.173a9.92 9.92 0 0 1 3.527-4.488A9.294 9.294 0 0 1 9.58 0h.737v4.67l.14-.226a9.68 9.68 0 0 1 4.3-3.683A9.205 9.205 0 0 1 20.29.192a9.461 9.461 0 0 1 4.904 2.737 10.143 10.143 0 0 1 2.622 5.12c.37 1.94.18 3.95-.545 5.778a9.92 9.92 0 0 1-3.528 4.488A9.294 9.294 0 0 1 18.42 20h-.737v-4.67a10.459 10.459 0 0 1-.14.226 9.68 9.68 0 0 1-4.3 3.683 9.205 9.205 0 0 1-5.534.569 9.461 9.461 0 0 1-4.904-2.737 10.143 10.143 0 0 1-2.622-5.12C-.186 10.01.004 8 .73 6.173ZM8.841 10V1.573a7.89 7.89 0 0 0-3.766 1.391A8.394 8.394 0 0 0 2.09 6.762a8.808 8.808 0 0 0-.462 4.889 8.583 8.583 0 0 0 2.219 4.332 8.006 8.006 0 0 0 4.15 2.316 7.789 7.789 0 0 0 4.683-.482 8.18 8.18 0 0 0 3.528-2.95 4.958 4.958 0 0 1-2.209.518c-2.849 0-5.158-2.411-5.158-5.385Zm10.316 8.427a7.89 7.89 0 0 0 3.766-1.391 8.393 8.393 0 0 0 2.985-3.798 8.807 8.807 0 0 0 .462-4.889 8.583 8.583 0 0 0-2.219-4.332 8.006 8.006 0 0 0-4.15-2.316 7.789 7.789 0 0 0-4.683.482 8.179 8.179 0 0 0-3.528 2.95A4.958 4.958 0 0 1 14 4.615c2.849 0 5.158 2.411 5.158 5.385v8.427Z"
                      />
                    </svg>
                    <span className="text-xs text-slate-400 dark:text-slate-500">
                      *Sponsor
                    </span>
                  </div>
                  <div className="font-aspekta font-[650] mb-1">
                    Build The Site You Want!
                  </div>
                  <p className="text-sm text-slate-500 dark:text-slate-400">
                    Your website should be an asset, not an engineering
                    challenge.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
