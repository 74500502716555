import Footer from "src/components/Footer";
import SEO from "src/components/SEO";
import Sidebar from "src/components/sidebar";
import Testimonials from "src/components/testimonials";
import PostsList from "src/components/postslist";
import { UIButton } from "src/components/ui/moving-border";
import FloatingTestimonial from "src/components/FloatingTestimonial";
import PressLogos from "src/components/PressLogos";
import Features from "src/components/Features";
const styles = {
  "@keyframes rotate": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
};

const testimonials = [
  {
    img: "https://randomuser.me/api/portraits/women/75.jpg",
    name: "Jennie",
    username: "@jennie",
    date: "May 10, 2024",
    content: "Found the perfect course thanks to AI counsellor on #AirCampus. #Learning #AI",
    channel: "Twitter",
  },
  {
    img: "https://randomuser.me/api/portraits/women/92.jpg",
    name: "Minnie",
    username: "@minnie",
    date: "Apr 12, 2027",
    content: "AI counsellor on #AirCampus made choosing courses so easy! #Upskill #Education",
    channel: "Twitter",
  },
  {
    img: "https://randomuser.me/api/portraits/men/2.jpg",
    name: "James Brown",
    username: "@james",
    date: "Mar 04, 2027",
    content: "Thanks to #AirCampus AI counsellor, I'm on the right path! #Upskilling",
    channel: "Twitter",
  },
  {
    img: "https://randomuser.me/api/portraits/men/4.jpg",
    name: "Michael Ross",
    username: "@michjack",
    date: "Jan 15, 2027",
    content: "AI counsellor on #AirCampus helped me choose the best course for me! #Learning",
    channel: "Twitter",
  },
  {
    img: "https://randomuser.me/api/portraits/women/5.jpg",
    name: "Emily Davis",
    username: "@edavis",
    date: "Jan 15, 2027",
    content: "Thanks to #AirCampus, I'm upskilling faster than ever! #AI #Education",
    channel: "Twitter",
  },
  
];

const testimonialsTwo = [
  {
    img: "https://randomuser.me/api/portraits/men/6.jpg",
    name: "John Miller",
    username: "@millerj",
    date: "May 10, 2024",
    content: "AI counsellor on #AirCampus helped me find my passion. #Upskilling #FutureReady",
    channel: "Twitter",
  },
  {
    img: "https://randomuser.me/api/portraits/women/7.jpg",
    name: "Olivia Garcia",
    username: "@livgarcia",
    date: "Apr 12, 2027",
    content: "Upskilling made easy with #AirCampus AI counsellor. Highly recommend! #AI #AirCampus",
    channel: "Twitter",
  },
  {
    img: "https://randomuser.me/api/portraits/men/8.jpg",
    name: "David M",
    username: "@davidm",
    date: "Mar 04, 2027",
    content: "AI counsellor on #AirCampus is amazing! Perfect course match. #Learning #FutureReady",
    channel: "Twitter",
  },
  {
    img: "https://randomuser.me/api/portraits/men/10.jpg",
    name: "Daniel",
    username: "@daniel",
    date: "Jan 15, 2027",
    content: "AirCampus #AI #Mentor helped me throughtout my course",
    channel: "Twitter",
  },
  {
    img: "https://randomuser.me/api/portraits/men/12.jpg",
    name: "Henry Taylor",
    username: "@henrytaylor",
    date: "Jan 15, 2027",
    content: "Thanks to #AirCampus, for provinding new way for learning #AI #Program",
    channel: "Twitter",
  },
  
];

export default function Home(props: any) {
  return (
    <>
      <SEO
        title="Online AI Learning Campus - Air Campus"
        description=""
        keywords=""
        canonicalUrl={"/"}
        authorName="Trendingtags"
        ogType="website"
      />
      <section className="relative overflow-hidden">
        {/* Bg */}
        <div
          className="absolute inset-0 bg-gradient-to-b from-indigo-100 to-white pointer-events-none -z-10"
          aria-hidden="true"
        />

        {/* Illustration */}
        <div
          className="hidden md:block absolute left-1/2 -translate-x-1/2 pointer-events-none -z-10"
          aria-hidden="true"
        >
          <img
            src={"/assets/images/hero-illustration.svg"}
            className="max-w-none"
            alt="Hero Illustration"
          />
        </div>

        <div className="max-w-6xl mx-auto">
          <div className="w-full md:flex pt-28 pb-8 md:pt-36 md:pb-8">
            {/* Hero content */}
            <div className="max-w-3xl text-center md:text-left md:w-3/6 ml-2">
              {/* Copy */}
              <h1 className="text-5xl font-extrabold font-inter mb-6">
                Join Online AI Learning Campus -{" "}
                <span className="font-nycd text-[#3F4080] font-extrabold">
                  Air Campus
                </span>
              </h1>
              <p className="text-lg text-gray-500 mb-8">
              Level up your skill with Online AI Learning Campus and advance your career with flexible, interactive learning experiance.
              </p>

              {/* Button + Avatars */}
              <div className="sm:flex sm:items-center sm:justify-center md:justify-start space-y-6 sm:space-y-0 sm:space-x-5">
                <div className="">
                  <a
                    className="btn hover:bg-gray-700 bg-[#3F4080] text-white text-lg h-12  font-medium border border-slate-50 shadow-sm "
                    href="/"
                  >
                    Join Air Campus
                  </a>
                </div>

                <div className="sm:flex sm:items-center sm:justify-center space-y-2 sm:space-y-0 sm:space-x-3">
                  <div className="inline-flex -space-x-3 -ml-0.5">
                    <img
                      className="rounded-full border-2 border-indigo-50 box-content"
                      src={"/assets/images/avatar-01.jpg"}
                      width={32}
                      height={32}
                      alt="Avatar 01"
                    />
                    <img
                      className="rounded-full border-2 border-indigo-50 box-content"
                      src={"/assets/images/avatar-02.jpg"}
                      width={32}
                      height={32}
                      alt="Avatar 02"
                    />
                    <img
                      className="rounded-full border-2 border-indigo-50 box-content"
                      src={"/assets/images/avatar-03.jpg"}
                      width={32}
                      height={32}
                      alt="Avatar 03"
                    />
                    <img
                      className="rounded-full border-2 border-indigo-50 box-content"
                      src={"/assets/images/avatar-04.jpg"}
                      width={32}
                      height={32}
                      alt="Avatar 04"
                    />
                  </div>
                  <div className="text-sm text-gray-500 font-medium">
                    <span className="text-gray">1200+</span>{" "}
                    Students Joined
                  </div>
                </div>
              </div>
            </div>

            <div className="relative md:w-[36rem] md:top-[-25px]">
              <div className=" group inline-flex w-full [mask-image:_linear-gradient(to_right,transparent_0,_black_10%,_black_90%,transparent_100%)]">
                {/* Duplicated element for infinite scroll */}
                <div
                  className="flex animate-[infinite-scroll_30s_linear_infinite] items-start justify-center group-hover:[animation-play-state:paused] md:justify-start [&>*]:mx-2"
                  aria-hidden="true"
                >
                  {/* Items */}
                  {testimonials.map((testimonial, index) => (
                    <FloatingTestimonial
                      key={index}
                      testimonial={testimonial}
                      className="w-[14rem] transition-transform duration-200 group-hover:rotate-0"
                    >
                      {testimonial.content}
                    </FloatingTestimonial>
                  ))}
                </div>
                <div
                  className="flex animate-[infinite-scroll_30s_linear_infinite] items-start justify-center group-hover:[animation-play-state:paused] md:justify-start [&>*]:mx-2"
                  aria-hidden="true"
                >
                  {/* Items */}
                  {testimonials.map((testimonial, index) => (
                    <FloatingTestimonial
                      key={index}
                      testimonial={testimonial}
                      className="w-[14rem] transition-transform duration-200 group-hover:rotate-0"
                    >
                      {testimonial.content}
                    </FloatingTestimonial>
                  ))}
                </div>
              </div>
              <div className="group inline-flex w-full mt-4 [mask-image:_linear-gradient(to_right,transparent_0,_black_10%,_black_90%,transparent_100%)]">
                {/* Duplicated element for infinite scroll */}
                <div
                  className="flex animate-[infinite-scroll_35s_linear_infinite] items-start justify-center group-hover:[animation-play-state:paused] md:justify-start [&>*]:mx-2"
                  aria-hidden="true"
                >
                  {/* Items */}
                  {testimonialsTwo.map((testimonial, index) => (
                    <FloatingTestimonial
                      key={index}
                      testimonial={testimonial}
                      className="w-[14rem] transition-transform duration-200 group-hover:rotate-0"
                    >
                      {testimonial.content}
                    </FloatingTestimonial>
                  ))}
                </div>
                <div
                  className="flex animate-[infinite-scroll_35s_linear_infinite] items-start justify-center group-hover:[animation-play-state:paused] md:justify-start [&>*]:mx-2"
                  aria-hidden="true"
                >
                  {/* Items */}
                  {testimonialsTwo.map((testimonial, index) => (
                    <FloatingTestimonial
                      key={index}
                      testimonial={testimonial}
                      className="w-[14rem] transition-transform duration-200 group-hover:rotate-0"
                    >
                      {testimonial.content}
                    </FloatingTestimonial>
                  ))}
                </div>
              </div>
              {/* <div className="relative">
                <img
                  className="full"
                  src="https://preview.cruip.com/community/images/hero-image.png"
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
        {/* <PressLogos /> */}
        {/* features */}
        <Features />
      <section>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-8 md:py-16">
            <div className="md:flex md:justify-between" data-sticky-container>
              {/* Main content */}
              <div className="md:grow">
                {/* <PostsList /> */}
                <PostsList />
              </div>

              <Sidebar />
            </div>
          </div>
          {/* <div>
            <Testimonials />
          </div> */}
        </div>
      </section>
    </>
  );
}
