import "./App.css";
// import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import { Adsense } from "@ctrl/react-adsense";
import { HelmetProvider } from "react-helmet-async";
import parentRoutes from "./routes/parentRoutes";
// import ReactGA from 'react-ga4';
import Home from "./pages/home";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import PageLayout from "./layouts/PageLayout";

// Google Analytics
// ReactGA.initialize("#");
// console.log('pageview', `${window.location.pathname + window.location.search}`);
// ReactGA.send({ hitType: "pageview", page: `${window.location.pathname + window.location.search}`});

// const pageRoutes = [...parentRoutes];

const horizontalAd = () => {
  return (
    <>
      {process.env.NODE_ENV === "development" ? (
        <div className="bg-red-500" style={{ width: "728px", height: "90px" }}>
          horizontalAd
        </div>
      ) : (
        <Adsense
          client="#"
          slot="#"
          style={{ display: "inline-block", width: "728px", height: "90px" }}
        />
      )}
    </>
  );
};

const multiplexAd = () => {
  return (
    <>
      {process.env.NODE_ENV === "development" ? (
        <div className="bg-red-500 h-6" style={{ display: "block" }}>
          MultiplexAd
        </div>
      ) : (
        <Adsense client="#" slot="#" style={{ display: "block" }} />
      )}
    </>
  );
};

const squareAd = () => {
  return (
    <>
      {process.env.NODE_ENV === "development" ? (
        <div className="bg-red-500 h-4" style={{ display: "block" }}>
          squareAd
        </div>
      ) : (
        <Adsense
          client="#"
          slot="#"
          style={{ display: "block" }}
          layout="in-article"
          format="fluid"
        />
      )}
    </>
  );
};

const verticalAd = () => {
  return (
    <>
      {process.env.NODE_ENV === "development" ? (
        <div className="bg-red-500 h-4" style={{ display: "block" }}>
          verticalAd
        </div>
      ) : (
        <Adsense
          client="#"
          slot="#"
          style={{ display: "block" }}
          format="auto"
        />
      )}
    </>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          {/* Routes for pages that use the <RootLayout> */}
          {/* {pageRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <RootLayout
                  verticalAd={verticalAd}
                  squareAd={squareAd}
                  multiplexAd={multiplexAd}
                >
                  {route.element({ horizontalAd, multiplexAd, squareAd })}
                </RootLayout>
              }
            />
          ))} */}

          {parentRoutes.map((routes, index) => (
            <Route
              key={index}
              path={routes.path}
              element={
                <PageLayout>
                  {routes.element({})}
                </PageLayout>
              }
            />
          ))}

          {/* Route for the root path, using no layout */}
          {/* <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} /> */}

          {/* Redirects all other paths to the root */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;
