import Home from "src/pages/home";
import Privacy from "src/pages/privacy";


const parentRoutes = [
  {
    path: '/',
    element: Home
  },
  {
    path: '/privacy',
    element: Privacy
  }
];

export default parentRoutes;
