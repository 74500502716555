export default function Newsletter() {
  return (
    <div className="rounded-lg border border-slate-200 p-5">
      <div className="text-center mb-1">
        <div className="inline-flex -space-x-3 -ml-0.5">
          <img
            className="rounded-full border-2 border-white  box-content"
            src={'/assets/images/avatar-01.jpg'}
            width={24}
            height={24}
            alt="Avatar 01"
          />
          <img
            className="rounded-full border-2 border-white  box-content"
            src={'/assets/images/avatar-02.jpg'}
            width={24}
            height={24}
            alt="Avatar 02"
          />
          <img
            className="rounded-full border-2 border-white  box-content"
            src={'/assets/images/avatar-03.jpg'}
            width={24}
            height={24}
            alt="Avatar 03"
          />
          <img
            className="rounded-full border-2 border-white  box-content"
            src={'/assets/images/avatar-04.jpg'}
            width={24}
            height={24}
            alt="Avatar 04"
          />
          <img
            className="rounded-full border-2 border-white  box-content"
            src={'/assets/images/avatar-01.jpg'}
            width={24}
            height={24}
            alt="Avatar 05"
          />
        </div>
      </div>
      <div className="text-center mb-8">
        <div className="font-aspekta font-[650] mb-1">Never miss an update!</div>
        <p className="text-sm text-slate-500">Subscribe and join 100K+ developers.</p>
      </div>
      <form>
        <div className="mb-2">
          <label className="sr-only" htmlFor="newsletter">
            Your email…
          </label>
          <input id="newsletter" type="email" className="form-input py-1 w-full" placeholder="Enter your email" />
        </div>
        <button className="rounded-md px-5 py-2 w-full text-slate-100 bg-sky-500 hover:bg-sky-600" type="submit">
          Subscribe
        </button>
      </form>
    </div>
  );
}
