import { Helmet } from 'react-helmet-async';
const domain = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://domain.com'
const SEO = ({title, description, keywords, canonicalUrl, authorName, ogType}:{title:any; description: any; keywords:any; canonicalUrl:any, authorName:any; ogType:any}) => {
    return (
        <Helmet>
        { /* Standard metadata tags */ }
        <title>{title}</title>
        <meta name='description' content={description} />
        <link rel="canonical" href={domain+canonicalUrl} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={keywords} />
        { /* End standard metadata tags */ }
        { /* Facebook tags */ }
        <meta property="og:type" content={ogType} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={domain+canonicalUrl} />
        <meta property="og:image" content="#" />
        { /* End Facebook tags */ }
        { /* Twitter tags */ }
        <meta name="twitter:creator" content={authorName} />
        <meta name="twitter:card" content={ogType} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        { /* End Twitter tags */ }
        </Helmet>
    )
}

export default SEO;