import HeaderLogo from "src/components/HeaderLogo";

export default function Header() {
  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto ">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="shrink-0 mr-4 flex">
              <img className="h-12" src="/assets/img/group-2.svg" />
          </div>

          {/* Desktop navigation */}
          <nav className="flex grow">
            {/* Desktop sign in links */}
            <ul className="flex grow justify-end flex-wrap items-center">
              <li>
                <a className="text-sm font-medium text-indigo-500 hover:underline px-3 lg:px-5 py-2 flex items-center" href="/">
                  Give Feedback
                </a>
              </li>
              {/* <li className="ml-3">
                <a className="btn-sm text-white bg-indigo-500 hover:bg-indigo-600 w-full shadow-sm" href="/post-a-job">
                  Join Now
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
