import PostItem from 'src/components/postitem'



export default function PostsList() {
  // const postsData: Promise<Post[]> = getAllPosts()
  const posts = [
    {
      "id": 0,
      "sticky": true,
      "title": "Introduction to Coding and Programming (IT)",
      "description": "Learn basics of programming languages like Python, Java, or C++. Understanding algorithms and data structures. Developing simple applications and projects",
      "image": "https://user-images.githubusercontent.com/2683512/225010250-6f37a62b-737f-4380-93da-8563a1c3c73f.svg",
      "tag1": "$75K - $100K",
      "tag2": "🇬🇧 London, UK",
      "date": "22d"
    },
    {
      "id": 1,
      "sticky": false,
      "title": "Web Development Fundamentals (IT)",
      "description": "HTML, CSS, and JavaScript basics. Introduction to web frameworks like React or Angular. Building and deploying a personal website or portfolio.",
      "image": "https://user-images.githubusercontent.com/2683512/225010338-b0c822c4-0583-491a-8914-826c9515dbaf.svg",
      "tag1": "Full Time",
      "tag2": "🌎 Remote",
      "date": "2h"
    },
    {
      "id": 2,
      "sticky": false,
      "title": "Digital Marketing Essentials (Marketing)",
      "description": "Basics of SEO, SEM, and content marketing. Social media marketing strategies. Introduction to tools like Google Analytics and AdWords.",
      "image": "https://user-images.githubusercontent.com/2683512/225010378-a5ce62f6-0ea3-4b9e-aed1-aee6b32ca6a7.svg",
      "tag1": "Full Time",
      "tag2": "🌎 Remote",
      "date": "2h"
    },
    {
      "id": 3,
      "sticky": true,
      "title": "Project Management Basics (Management)",
      "description": "Introduction to project management methodologies (Agile, Scrum). Tools like Trello, Asana, or Jira. Basics of project planning, scheduling, and resource management.",
      "image": "https://user-images.githubusercontent.com/2683512/225010410-86af0a77-9db9-40a2-915e-3fdbce9dad1c.svg",
      "tag1": "$100K - $170K",
      "tag2": "🇺🇸 NYC",
      "date": "4h"
    },
    {
      "id": 4,
      "sticky": false,
      "title": "Data Analytics and Visualization (IT/Management)",
      "description": "Introduction to data analysis with Excel or Google Sheets. Basics of data visualization tools like Tableau or Power BI. Understanding of statistical concepts and data interpretation.",
      "image": "https://user-images.githubusercontent.com/2683512/225010410-86af0a77-9db9-40a2-915e-3fdbce9dad1c.svg",
      "tag1": "$100K - $170K",
      "tag2": "🇺🇸 NYC",
      "date": "7h"
    },
    
  ]

  return (
    <div className="pb-8 md:pb-16">
      <h2 className="font-inter-tight text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-zinc-600 via-zinc-900 to-zinc-900 pb-4 ">Explore Programs</h2>
      {/* List container */}
      <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-5 ">

        {posts.map(post => {
          return (
            <PostItem key={post.id} {...post} />
          )
        })}
        

      </div>
    </div>
  )
}
