export default function PostItem({ ...props }) {
  return (
    <div className="flex flex-col rounded-lg border border-slate-200 hover:border-slate-300">
      <a
        className="relative h-32 aspect-video rounded-t-lg overflow-hidden bg-gradient-to-tr from-[#3F4080] to-slate-800 transition-transform duration-700 hover:duration-100 ease-in-out"
        href="#0"
      >
        <div className="relative flex flex-col items-start justify-between before:mt-auto before:flex-1 p-5">
          <div className="flex-1 flex items-center text-xl text-white font-[700]">
            {props.title}
          </div>
          
          <div className="flex-1 w-full flex justify-end items-end ">
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41">
              <circle
                className="fill-white"
                cx="20"
                cy="20"
                r="20"
                fillOpacity=".88"
              />
              <path
                className="fill-sky-500"
                d="m24.765 19.5-6.263-4.375a.626.626 0 0 0-1.002.5v8.75c0 .5.564.812 1.002.5l6.263-4.375a.65.65 0 0 0 0-1Z"
              />
            </svg>
          </div>
        </div>
      </a>

      <div className=" p-5 group">
        <div className="flex flex-col h-full">
          <div className="grow">
            <div className="flex items-center justify-between space-x-2">
              {/* <div className="h-10 w-10 flex items-center justify-center border border-slate-200 rounded-full mb-2">
              <img src={props.image} width="56" height="56" alt={props.name} />
            </div> */}
              {/* <div className="text-xs inline-flex items-center font-medium bg-green-100 text-green-600 rounded-full text-center px-2 h-5">
                Open-Source
              </div> */}
            </div>
            <div className="text-lg font-aspekta font-[650] mb-1">
              {props.title}
            </div>
            <p className="text-sm text-slate-500 mb-2">{props.description}</p>
          </div>
          <div className="text-sky-500 flex justify-end">
            <svg
              className="fill-current -rotate-45 group-hover:rotate-0 transition-transform ease-out"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="12"
            >
              <path d="M9.586 5 6.293 1.707 7.707.293 13.414 6l-5.707 5.707-1.414-1.414L9.586 7H0V5h9.586Z" />
            </svg>
          </div>

          {/* <div className="-m-1">
            <a
              className={`text-xs text-gray-500 font-medium inline-flex px-2 py-0.5 hover:text-gray-600 rounded-md m-1 whitespace-nowrap transition duration-150 ease-in-out ${
                props.sticky ? "bg-indigo-50" : "bg-gray-100"
              }`}
              href="#0"
            >
              {props.tag1}
            </a>
            <a
              className={`text-xs text-gray-500 font-medium inline-flex px-2 py-0.5 hover:text-gray-600 rounded-md m-1 whitespace-nowrap transition duration-150 ease-in-out ${
                props.sticky ? "bg-indigo-50" : "bg-gray-100"
              }`}
              href="#0"
            >
              {props.tag2}
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
